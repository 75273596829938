<template>
  <div id="SayHelloTo">
    <van-tabs v-model="activeName">
      <van-tab title="我打招呼的" name="a">
        <div class="personalInfo">
          <div v-for="(i,item) in wantGreet" :key="item" class="list" @click="toIntroDetail(i.member_id)">
            <div v-if="i.member_info">
              <img v-if="i.users" :src="i.users.headimgurl ? Global.changeImg(i.users.headimgurl) :'../../assets/image/home/image1.png'"/>
              <div >
                <p  class="first">{{i.member_info.name}}</p>
                <p  class="second">{{i.member_info.company}}{{i.member_info.job}}</p>
                <p  class="third">
                  <span v-if="i.member_info.industry == 1" >保险</span>
                  <span v-if="i.member_info.industry == 2" >创业</span>
                  <span v-if="i.member_info.industry == 3" >互联网</span>
                  <span v-if="i.member_info.industry == 4" >观察者</span>
                  <span v-if="i.member_info.industry == 5" >投资</span>
                  <span v-if="i.member_info.industry == 6" >媒体</span>
                </p>
              </div>
            </div>
            <span v-if="i.status==0" >等待验证</span>
            <span v-if="i.status==1" class="agree">已同意</span>
            <span v-if="i.status==2" class="noAgree">已拒绝</span>
          </div>
        </div>
      </van-tab>
      <van-tab title="向我打招呼的" name="b">
        <div class="personalInfo">
          <div v-for="(i,item) in byGreet" :key="item" class="list" @click="toIntroDetail(i.customer_id)">
            <div v-if="i.greet_member_info">
              <img v-if="i.greet_users" :src="i.greet_users.headimgurl ? Global.changeImg(i.greet_users.headimgurl) :'../../assets/image/home/image1.png'"/>
              <div >
                <p  class="first">{{i.greet_member_info.name}}</p>
                <p  class="second" v-if="i.greet_member_info.company || i.greet_member_info.job">{{i.greet_member_info.company}}{{i.greet_member_info.job}}</p>
                <p  class="second" v-else>这个人很懒，什么都没留下~</p>
                <p  class="third">
                  <span v-if="i.greet_member_info.industry == 1" >保险</span>
                  <span v-if="i.greet_member_info.industry == 2" >创业</span>
                  <span v-if="i.greet_member_info.industry == 3" >互联网</span>
                  <span v-if="i.greet_member_info.industry == 4" >观察者</span>
                  <span v-if="i.greet_member_info.industry == 5" >投资</span>
                  <span v-if="i.greet_member_info.industry == 6" >媒体</span>
                </p>
              </div>
            </div>
            <div>
              <span class="blue" v-if="i.status == 0" @click.stop="passWts(i.id,1,item)">同意</span>
              <span class="blue" v-if="i.status == 1">已同意</span>
              <span class="red" v-if="i.status == 0" @click.stop="passWts(i.id,2,item)">拒绝</span>
              <span class="red" v-if="i.status == 2">已拒绝</span>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name: "SayHelloTo",
  data() {
    return {
      byGreet:'',//向我打招呼
      wantGreet:'',//我向别人打招呼
      activeName: 'a',
    };
  },
  beforeMount(){
    if(this.$route.query.activeName){
      this.activeName = this.$route.query.activeName
    }
  },
  mounted() {
    this.axios({
      method: 'PATCH',
      url:'/notify/notifyRead',
      data:{
        type:2
      },
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    })
    // 我打招呼的
    this.axios({
      method: 'GET',
      url: '/recognition/wantGreet',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.wantGreet = res.data.data;
        console.log(this.wantGreet)
      }
    })
    // 向我打招呼的
    this.axios({
      method: 'GET',
      url: '/recognition/byGreet',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.byGreet = res.data.data;
      }
    })
  },
  methods: {
    // 同意与拒绝
    passWts(id,status,item){
      this.axios({
        method: 'PATCH',
        url: '/recognition/passWts/'+id,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          status:status
        }
      }).then((res) => {
        if(res.data.code == 0){
          if(status == 1){
            this.$toast('已同意')
            this.byGreet[item].status = 1
          }else{
            this.$toast('已拒绝')
            this.byGreet[item].status = 2
          }
          
        }
      })
    },
     // 跳转大咖详情
    toIntroDetail(id){
      this.axios({
        method: 'PATCH',
        url: '/master/alwaysVisitAdd',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$router.push({name:'Introduction',query: {id:id}})
          
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
#SayHelloTo {
  background: #23252E;
  .personalInfo{
    border-top: 1px solid #5C5D6E61;
    .list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: .1px solid #5C5D6E61;
      margin: 0 0.15rem;
      padding: 0.13rem 0;
      >div:first-child{
        display: flex;
        align-items: center;
        flex: 1;
        padding-right: 0.1rem;
        >img{
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
        }
        >div{
          padding: 0 0 0 0.15rem;
          position: relative;
          flex:1;
          .first{
            font-size: 0.16rem;
            color: #F6F7FD;
          }
          .third{
            display: flex;
            flex-wrap: wrap;
            >span{
              padding: 0 0.05rem;
              color: #9091A3;
              font-size: 0.12rem;
              border-radius: 0.02rem;
              background: #3D404F;
              margin: 0 0.05rem 0.05rem 0;
            }
          }
          .second{
            margin: 0.03rem 0 0.07rem 0;
            color: #989AB1;
            display: flex;
            font-size: 0.14rem;
            >span{
              display: flex;
              margin-left: 0.1rem;
              color: #989AB1;
            }
          }
        }
      }
      >span{
        color: #727387;
        font-size: 0.14rem;
      }
      .agree{
        color: #3890FF;
      }
      .noAgree{
        color: #E55959;
      }
      >div:last-child{
        display: flex;
        >span{
          height: 0.25rem;
          border-radius: 0.25rem;
          padding: 0 0.12rem;
          line-height: 0.25rem;
          font-size: 0.12rem;
          color: #F6F7FD;
        }
        .red{
          margin-left: 0.11rem; 
          background: #E55959;
        }
        .blue{
          background: #3890FF;
        }
      }
    }
  }
}
</style>